/** @format */

import React, { PureComponent } from "react";
import _ from "lodash";
import "../styles.scss";
import deleteIconRed from "../../../images/delete-icon-red.svg";
import CityOfOrigin from "../../BusinessAdmin/partials/CityOfOriginModal";
import { customStartCase, isRoundOff } from "../../../constants/common";

export default class OrederBreckdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCityOfOriginModalOpen: false,
      pricingTierList: [],
      cityOfOrigin: [],
      pricingTier: {},
      selectedchargeByYard: {
        value: 0,
        label: "",
      },
      selectedchargeByYardUnit: 0,
      selectedChargeByWeightInToneUnit: 0,
      dumpRateSalesTax: {},
      selectedChargeByWeightInTone: 0,
      addSelectedFixedItems: this.props?.selectedFixedItemsArray?.length > 0 ?
      this.props?.selectedFixedItemsArray
      : [],
      addSelectedChargeByWeight: this.props.selectedChargeByWeightArray?.length > 0 ?
      this.props.selectedChargeByWeightArray : [],
      fixedItemsArray: [],
      chargeByweightItemsArray: [],
      reRender: true,
      isminimumPrice: this.props?.orderBreakDown?.isminimumPrice ?? false,
      isCapOutPrice: this.props?.orderBreakDown?.isCapOutPrice ?? false,
      totalMinimumPrice: this.props?.orderBreakDown?.minimumPrice ?? 0,
      totalCapoutPrice: this.props?.orderBreakDown?.totalCapoutPrice ?? 0,
      totalOrderAmout: this.props?.orderBreakDown?.totalOrderAmout ?? 0,
      totalAmountDue: this.props?.orderBreakDown?.totalAmountDue ?? 0,
      addselectedLabels:this.props?.selectedFixedItemsArray?.length > 0 ?
      this.props?.selectedFixedItemsArray?.map(f => f?.fixedItem)
      :[],
      addselectedChargeByWeightLabels:this.props?.selectedChargeByWeightArray?.length > 0 ?
      this.props?.selectedChargeByWeightArray?.map(f => f?.fixedItem)
      :[],
      totalYardageunit:0,
      totalTonnageUnit:0,
      activeTab:"",
      totalWithFee:{},
      user:!this.props?.user?.company ? {} : this.props?.user,
      fuelSurchargeFeeAmmount: this.props?.orderBreakDown?.fuelSurchargeAmmount ?? 0,
      saleTaxFeeAmount: this.props?.orderBreakDown?.saleTaxFeeAmount ?? "",
      tax: this.props?.orderBreakDown?.saleTaxFeeAmount ?? 0,
      handlingFee:0,
      isActualRoundOffAmount : false
    };
  }

  componentDidMount = async () => {
    const companyId = this.props.customer?.companyId;
    this.getPricingTier();
    this.fetchSettings(companyId);
    this.setState({user:this.props.user})
  };

  calcFee(amount, currency) {
    const _fee = this.state.user?.company?.chargeStripeCardFeesAmount ? this.state.user?.company?.chargeStripeCardFeesAmount : "3"
    let fee = 0
    amount = this.round2decimal(amount);
    fee =
      amount * (this.round2decimal(_fee) / 100) 
    fee = this.round2decimal(fee);
    const total = this.round2decimal(amount + fee);
  
      return {
      amount: this.round2decimal(amount),
      fee: this.round2decimal(fee),
      total: this.round2decimal(total),
    };
  }

  componentDidUpdate=async (prevProps)=>{
    if (
      prevProps.isEnvironmentalLoadFee!== this.props.isEnvironmentalLoadFee ||
      prevProps.isFuelSurchardeFee!== this.props.isFuelSurchardeFee|| this.props.isCalculate 
    ) {
      this.calculateTotalOrderAmount()
    }
   this.setState({ activeTab: this.props.activeTab,totalWithFee:this.props.totalWithFee})
   const numberInputs = document.querySelectorAll('input[type="number"]');
    numberInputs.forEach((input) => {
      input.addEventListener('wheel', this.handleWheel);
    });
   if (
    this.props.scaleItemsErrors === prevProps.scaleItemsErrors &&
    this.props.fixedItemsErrors === prevProps.fixedItemsErrors
  ) {
    this.updateErrorsAccToFeilds();
  }
    this.setState({user:this.props.user})
  }
  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  getPricingTier = async () => {
    const id = this.props.pricingTier;
    const {
      value: { data, status },
    } = await this.props.getPricingTierById(id);
    if (status) {
      this.setState({ pricingTier: data.pricing , dumpRateSalesTax : data.salesTaxPricingTier.dumpRates});
      await this.props.getPricingTier(data.pricing)
     await  this.assignFixedItems(
        data.pricing
      );
      await this.calculateTotalOrderAmount()
    }
  };

  numberWithCommas = (x) => {
    var parts = x?.toFixed(2)?.split(".");
    var num = parts?.[0]?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts?.[1] ? "." + parts?.[1] : "");
    return num;
  }

  // roundAmount=(amount)=>{
  //   if(this.props.selectedSplitPaymentMethods.includes("cash") && this.props.selectedSplitPaymentMethods.length === 1 ) {
    //   return Math.ceil(amount)
    //   }else{
      //     return amount
      //   }
      // }

      calculateSalesTax =(value) => {
        let salesTaxFeeAmount = 0
        if (this.props?.isSalesTax) {
          salesTaxFeeAmount = this.round2decimal( (this.props.saleTaxFeeAmount ?? 0) / 100 * this.round2decimal(value));
          
        }
        return this.round2decimal(salesTaxFeeAmount);
       }
      
  matchFixedItem = (item) =>{
    let value = false
    const {dumpRateSalesTax } = this.state;
    const keys = Object?.keys(dumpRateSalesTax);
    const normalizedItem = (item.fixedItem).toLowerCase().replace(/\s+/g, '');
    const match = keys.find(key => (key).toLowerCase().replace(/\s+/g, '') === normalizedItem);
    if (match) {
       value = dumpRateSalesTax[match];
    }
    return value
  }
      
  calculateTotalOrderAmount = async () => { 
    const parentComponentState=this.props.parentComponentState
    const { addSelectedFixedItems, addSelectedChargeByWeight, pricingTier } = this.state;
    let totalChargeByWeight = 0
    let totalunitPrice = 0;
    let totalUnits = 0;
    let totalWithOutYardPrice = 0;
    let totalWithYardPrice=0
    let totalCapoutPrice = 0;
    let totalfixedItemAmout = 0;
    let totalOrderAmout = 0;
    let minimumPrice = 0;
    let totalAmountDue = 0;
    let totalTonnageUnit=0;
    let capPrice=0;
    let isminimumPrice=false;
    let isCapOutPrice=false;
    let fuelSurchargeAmmount = 0;
    let envLoadFee = 0
    let salesTaxFeeAmount = 0;

    this.setState({ isCapOutPrice: false })
    isCapOutPrice=false
    addSelectedFixedItems.map((item) => {
      totalfixedItemAmout += item.unitPrice * (item.units >= 0 ? item.units : 0);
      let yerdArray = item?.fixedItem.split(" ")
      if (yerdArray[2] === "Yard") {
        if(Number( pricingTier.capoutPrice) < item.unitPrice && pricingTier.capoutPrice > 0 ){
          capPrice=capPrice + pricingTier.capoutPrice * item.units
          isCapOutPrice=true;
          this.setState({ isCapOutPrice: true })
           // sales tax calculation for price par yard with capout  items
          salesTaxFeeAmount += this.matchFixedItem(item) ? this.calculateSalesTax((pricingTier.capoutPrice * item.units)) : 0
        }else{
           // sales tax calculation for price par yard items
          salesTaxFeeAmount += this.matchFixedItem(item) ? this.calculateSalesTax(Number(item.unitPrice)*Number(item.units)) :0
          totalWithYardPrice=totalWithYardPrice + Number(item.unitPrice)*Number(item.units)
        }
        totalunitPrice = totalunitPrice + Number(item.unitPrice);
        totalUnits = totalUnits + Number(item.units);
      } else {
        totalWithOutYardPrice = totalWithOutYardPrice + Number(item.unitPrice) * Number(item.units);
        // sales tax calculation for addtional items
        pricingTier?.additionalItems && pricingTier.additionalItems.map((fixedItem)=>{
          if(item.fixedItem === fixedItem.label){
            salesTaxFeeAmount +=  fixedItem.saleTax ? this.calculateSalesTax((Number(item.unitPrice * item.units))) : 0 ;
          }
          return null
       })
      }
      return null;
    })

    addSelectedChargeByWeight.map((item) => {
      salesTaxFeeAmount +=  this.matchFixedItem(item) ? this.calculateSalesTax(Number(item.unitPrice * item.units)) : 0 ;
      totalChargeByWeight = totalChargeByWeight + (Number(item.unitPrice * item.units))
      totalTonnageUnit=totalTonnageUnit + Number(item.units)
      return null;
    })
    totalOrderAmout = totalfixedItemAmout + totalChargeByWeight
    totalCapoutPrice = totalChargeByWeight + totalWithOutYardPrice + capPrice +totalWithYardPrice
    let totalTonAndYard = totalChargeByWeight + capPrice + totalWithYardPrice
    // if ( pricingTier.capoutPrice > 0) {
      //   // totalCapoutPrice = totalChargeByWeight + totalWithOutYardPrice + Number(pricingTier.capoutPrice) * totalUnits
      //   totalCapoutPrice = totalChargeByWeight + totalWithOutYardPrice + capPrice +totalWithYardPrice
      //   // this.setState({ isCapOutPrice: true })
      // } else {
        //   this.setState({ isCapOutPrice: false })
        // }
        let total = isCapOutPrice ? totalCapoutPrice : totalOrderAmout
        if(Number(totalTonAndYard) > 0 && (Number(pricingTier.minimumPrice) > Number(totalTonAndYard))){
          isminimumPrice = true
          totalAmountDue = Number(pricingTier.minimumPrice)
          this.setState({ isminimumPrice: true })
          salesTaxFeeAmount = (salesTaxFeeAmount - this.calculateSalesTax(totalTonAndYard)) + this.calculateSalesTax(pricingTier.minimumPrice)
        }else{
          this.setState({ isminimumPrice: false })
        }
        totalAmountDue += Number(totalWithOutYardPrice)
      if(this.props?.isEnvironmentalLoadFee){
         total +=this.props.user.company.environmentalLoadFeeAmount
         totalOrderAmout +=this.props.user.company.environmentalLoadFeeAmount
         totalAmountDue +=this.props.user.company.environmentalLoadFeeAmount
         envLoadFee = this.props.user.company.environmentalLoadFeeAmount
         salesTaxFeeAmount += this.calculateSalesTax(this.props.user.company.environmentalLoadFeeAmount) 
      }
      if(this.props?.isFuelSurchardeFee){
       let fuelSurchardeFeeAmount = this.props.user.company.fuelSurchardeFeeAmount;
      let  fuelSurchargeTotal = this.round2decimal(
          fuelSurchardeFeeAmount / 100 *
            this.round2decimal(totalOrderAmout)
        );
      let  fuelSurchargeOnAmountDue = this.round2decimal(
          fuelSurchardeFeeAmount / 100 *
            this.round2decimal(totalAmountDue)
        );
      let  fuelSurchargeCapout = this.round2decimal(
          fuelSurchardeFeeAmount / 100 *
            this.round2decimal(totalCapoutPrice+Number(envLoadFee))
        );
        fuelSurchargeAmmount = isminimumPrice ? fuelSurchargeOnAmountDue : isCapOutPrice ? fuelSurchargeCapout : fuelSurchargeTotal
        total += isCapOutPrice ? fuelSurchargeCapout :  fuelSurchargeTotal
          totalOrderAmout +=fuelSurchargeTotal
          totalAmountDue +=fuelSurchargeOnAmountDue
          salesTaxFeeAmount += this.calculateSalesTax(fuelSurchargeAmmount) 
      }
    // if (isCapOutPrice) {
    //   const amount = totalCapoutPrice + fuelSurchargeAmmount + envLoadFee
    //   if (amount < Number(pricingTier.minimumPrice) &&  Number(pricingTier.minimumPrice) > 0) {
    //     minimumPrice = amount;
    //     totalAmountDue = Number(pricingTier.minimumPrice);
    //     isminimumPrice=true
       
    //     this.setState({ isminimumPrice: true });
    //   } else {
    //     isminimumPrice=false
    //     this.setState({ isminimumPrice: false })
    //     totalAmountDue = amount
    //   }
    // } else {
    //   if (totalOrderAmout < pricingTier.minimumPrice &&  Number(pricingTier.minimumPrice) > 0) {
    //     minimumPrice = totalOrderAmout;
    //     totalAmountDue = Number(pricingTier.minimumPrice);
    //     isminimumPrice=true
    //     this.setState({ isminimumPrice: true })
    //   } else {
    //     isminimumPrice=false
    //     this.setState({ isminimumPrice: false })
    //     totalAmountDue = totalOrderAmout;
    //   }
    // }
    let fee = 0
    let withOutFeeAndRoundOffTotal= isminimumPrice ? totalAmountDue : total
    totalAmountDue = this.round2decimal(totalAmountDue +  parentComponentState.totalHandlingFeeAmount)
    total = this.round2decimal(total + parentComponentState.totalHandlingFeeAmount )
    let splitpaymentCalculationAmount = isminimumPrice ?  totalAmountDue: total
    if ((parentComponentState.selectedSplitPaymentMethods.includes("credit-card") || parentComponentState.selectedSplitPaymentMethods.includes("terminal")) && this.state.user?.company?.chargeStripeCardFees){
       parentComponentState.splitPayments.map((payment)=>{
        if(parentComponentState.selectedSplitPaymentMethods.includes(payment.paymentMethod) &&((payment.paymentMethod ==="credit-card" || payment.paymentMethod==="terminal")) && payment.isSelectedPayment && ( !parentComponentState.isSplitPaymentOption || payment.paymentAmount > 0)){
            fee = Number((this.calcFee(parentComponentState.isSplitPaymentOption ? payment.paymentAmount : withOutFeeAndRoundOffTotal ,"USD").fee).toFixed(2))
           totalAmountDue += fee
           total += fee
        }
        return null
       })
    }
    this.setState({handlingFee:fee,isActualRoundOffAmount:false})
    total += salesTaxFeeAmount
    totalOrderAmout += salesTaxFeeAmount
    totalAmountDue +=salesTaxFeeAmount
    if( parentComponentState.isRoundOffAmount && !parentComponentState.paidAmount > 0 && parentComponentState.selectedSplitPaymentMethods.includes("cash") && parentComponentState.selectedSplitPaymentMethods.length === 1){
      if(totalAmountDue !== Math.ceil(totalAmountDue) || total !== Math.ceil(total)){
        this.setState({isActualRoundOffAmount : true})
      }else{
        this.setState({isActualRoundOffAmount : false})
      }
      totalAmountDue=Math.ceil(totalAmountDue)
      total=Math.ceil(total)
      splitpaymentCalculationAmount = isminimumPrice ?  totalAmountDue: total
    }
    else{
      if( parentComponentState.isRoundOffAmount && (parentComponentState.paidAmount > 0 || parentComponentState.selectedSplitPaymentMethods.length > 1) &&  parentComponentState.selectedSplitPaymentMethods.includes("cash")){
         parentComponentState.splitPayments.map((payment)=>{
          if(payment.paymentMethod ==="cash" && payment.paymentAmount > 0 ){
            if( payment.paymentAmount !== Math.ceil(payment.paymentAmount)){
              this.setState({isActualRoundOffAmount : true})
            }else{
              this.setState({isActualRoundOffAmount : false})
            }
          salesTaxFeeAmount +=  this.calculateSalesTax( Math.ceil(payment.paymentAmount) - payment.paymentAmount )
           totalAmountDue= Number((((totalAmountDue - payment.paymentAmount))+ Math.ceil(payment.paymentAmount)).toFixed(2))
           total = Number(Number(total-payment.paymentAmount).toFixed(2)) + Math.ceil(payment.paymentAmount)
          }
          return null
        })
      }
    }
    this.setState({ totalMinimumPrice: minimumPrice,
                  totalCapoutPrice: totalCapoutPrice,
                  totalOrderAmout: totalOrderAmout,
                  totalAmountDue:isminimumPrice ? totalAmountDue:total,
                  totalYardageunit:totalUnits,
                  totalTonnageUnit:totalTonnageUnit,
                  tax : salesTaxFeeAmount,
                  fuelSurchargeFeeAmmount:fuelSurchargeAmmount
                })
    this.props.totalAmountDue(isminimumPrice ?  totalAmountDue : total,splitpaymentCalculationAmount)
    this.props.yardage(totalUnits)
    this.props.tonnage(totalTonnageUnit)
    this.props.assignFixedItem(this.state.addSelectedFixedItems, this.state.addSelectedChargeByWeight)
    this.props.assignhandlingfee(fee,withOutFeeAndRoundOffTotal)
     const payalod={
      fixedItem:addSelectedFixedItems,
      chargeByWeight:addSelectedChargeByWeight,
      isCapOutPrice:isCapOutPrice,
      isminimumPrice:isminimumPrice,
      minimumPrice:pricingTier.minimumPrice,
      salesTaxFeeAmount : salesTaxFeeAmount,
      capoutPrice:pricingTier.capoutPrice,
      totalAmountDue:isminimumPrice ?  totalAmountDue: total,
      totalCapoutPrice:totalCapoutPrice,
      totalOrderAmout:totalOrderAmout,
      fuelSurchargeAmmount,
      originalAmount:total,
     }
     this.props.isCalculteOrder(false)
    this.props.orderDetails(payalod)
  };

  assignSelectedFixedItem= async (e,index)=>{
    let addSelectedFixedItems =
    this.state.addSelectedFixedItems;
    let addselectedLabels=this.state.addselectedLabels;
    let obj={};
    this.state.fixedItemsArray.map((item)=>{
       if(e.target.value === item.label){
        if(item?.dumpRateType && item?.dumpRateType ==="fixedItem"){
        obj={
          dumpRateType:"fixedItem",
          fixedItem:item.label,
          unitPrice:Number(item.value),
          units: "",
        }
        }else{
        obj = {
          fixedItem:item.label,
          unitPrice:Number(item.value),
          units: "",
        };
      }
       }
       return null
    })
  addSelectedFixedItems[index] = obj;
  
  addselectedLabels[index]= e.target.value;
  this.setState({addselectedLabel:addselectedLabels})
  this.setState({
    addSelectedFixedItems: addSelectedFixedItems,
  });
  this.setState({
    reRender: !this.state.reRender,
  });
  }

  assignSelectedChargeByWeight= async (e,index)=>{
    let addSelectedChargeByWeight = this.state.addSelectedChargeByWeight;
    let addselectedChargeByWeightLabels=this.state.addselectedChargeByWeightLabels
   let obj={};
    this.state.chargeByweightItemsArray.map((item)=>{
      if(e.target.value === item.label){
        obj = {
          fixedItem:item.label,
          unitPrice:Number(item.value),
          units: "",
        };
      }
      return null
   })

  addSelectedChargeByWeight[index] = obj;
  addselectedChargeByWeightLabels[index]=e.target.value;
  this.setState({
    addSelectedChargeByWeight:
      addSelectedChargeByWeight,addselectedChargeByWeightLabels:addselectedChargeByWeightLabels
  });
  this.setState({
    reRender: !this.state.reRender,
  });
  }

  assignFixedItems = async (pricingTier) => {
    let chargeByWeight = [];
    const fixedItem = [];
    const fixedItemsArray = []
    const chargeByWeightArray = []
    for (const key in pricingTier.dumpRates) {
      if (pricingTier?.dumpRates.hasOwnProperty(key)) {
        const label = customStartCase(key, "");
        if (key.includes("pricePerYard")) {
          fixedItemsArray.push({
            value: pricingTier?.dumpRates[key],
            label: label
          })
        } else if (key.includes("pricePerTon")) {
          chargeByWeightArray.push({
            value: pricingTier?.dumpRates[key],
            label: label
          })
        }
      }
    }

    fixedItemsArray.map((item) => {
      if (Number(item.value) > 0) {
        fixedItem.push(item);
      }
      return null;
    });
    pricingTier.additionalItems.map((item) => {
      let obj = {};
      if (Number(item.value) > 0) {
        obj["value"] = item.value;
        obj["label"] = item.label;
        obj["dumpRateType"]="fixedItem"
        fixedItem.push(obj);
      }
      return null;
    });

    chargeByWeightArray.map((item) => {
      if (Number(item.value) > 0) {
        chargeByWeight.push(item);
      }
      return null;
    });
    this.setState({ fixedItemsArray: fixedItem });
    this.setState({ chargeByweightItemsArray: chargeByWeight });
  };

  fetchSettings = async (companyId) => {
    const {
      value: {
        data: {
          cities,
        },
      },
    } = await this.props.fetchSetting(companyId);
    this.setState({ cityOfOrigin: cities });
  };

  handleWheel = (e) => {
    e.preventDefault();
    const scrollAmount = e.deltaY > 0 ? 100 : -100; // Adjust the scroll distance as needed

    window.scrollBy({
      top: scrollAmount,
      behavior: 'smooth',
    });
  };

  componentWillUnmount() {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    numberInputs.forEach((input) => {
      input.removeEventListener('wheel', this.handleWheel);
    });
  }
  updateErrorsAccToFeilds = async()=>{
    const addSelectedChargeByWeight = this.state.addSelectedChargeByWeight;
    const addSelectedFixedItems = this.state.addSelectedFixedItems;
    const fixedItemsErrors = [];
    const scaleItemsErrors = [];
    addSelectedFixedItems.forEach((item, index) => {
      const {unitPrice,units,fixedItem , clickAfterComplete}=item
      if(clickAfterComplete === false){
      fixedItemsErrors[index]={
        unitPrice:unitPrice && unitPrice > 0 ? "" : "Unit Price is required",
        unit:units && units > 0 && units !== "" ? "" : "Units is required",
        fixedItem:fixedItem && fixedItem !== "" ? "" : "Fixed Item is required"
      }
    }
    })
    addSelectedChargeByWeight.forEach((item, index) => {
      const {unitPrice,units,fixedItem,clickAfterComplete}=item
      if(clickAfterComplete === false){
      scaleItemsErrors[index]={
        unitPrice:unitPrice && unitPrice > 0 ? "" : "Unit Price is required",
        unit:units && units > 0 && units !== "" ? "" : "Tonnage is required",
        fixedItem:fixedItem && fixedItem !== "" ? "" : "Charge by weight item is required"
      }
    }
    })
    this.props.updateErrors({fixedItemsErrors,scaleItemsErrors})
  }

  calculateTotalAmountDue() {
    const { activeTab, totalAmountDue, user, pricingTier, totalWithFee } = this.state;
  
    if (activeTab === "cash") {
      return isRoundOff(totalAmountDue)(this.props.isRoundOffAmount);
    } else if (activeTab === "creditCard") {
      if (user?.company?.chargeStripeCardFees) {
        if (totalWithFee.total < Number(pricingTier.minimumPrice)) {
          return Number(totalAmountDue);
        } else {
          return Number(totalWithFee.total);
        }
      }
    }
    return totalAmountDue;
  }

  render() {
    const {
      cityOfOrigin,
      pricingTier,
      fixedItemsArray,
      addSelectedFixedItems,
    } = this.state;
    const {scaleItemsErrors, fixedItemsErrors, showErrors} = this.props;
    return (
      <div>
        <section className='order__breakdown-section'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='order__title'>Order Breakdown</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-sm-12'>
              <div className='form-group material-textfield'>
                <div className="disable__items">
                <h3 className="title">Pricing Tier<span className="red-text">*</span></h3>
                  <h4 className="sub-title">{this.state.pricingTier?.pricingTier ? this.state.pricingTier?.pricingTier : "Standard Cash Pricing Tier"}</h4>
                </div>
                {/* <select
                  className='form-control custom-select material-textfield-input'
                  required
                  disabled={true}
                >
                  <option>{this.state.pricingTier?.pricingTier} </option>
                </select>
                <label className='material-textfield-label'>
                  Pricing Tier<span>*</span>
                </label> */}
              </div>
            </div>
            <div className='col-lg-6 col-sm-12'>
              <div className='form-group material-textfield'>
                <select
                  className='form-control custom-select material-textfield-input'
                  onChange={(e) => {
                    if (e.target.value === "Add New") {
                      this.setState({ isCityOfOriginModalOpen: true, cityOfOrigin: _.get(this.state.user, "company.cities", []) })
                    } else {
                      this.props.cityOfOrigin(e.target.value)
                    }
                  }}
                  value={this.props?.cityOfOriginName}
                  required>
                  <option>Select</option>
                  { cityOfOrigin?.length > 0 && cityOfOrigin?.map((item, index) => (
                      <option key={item}>{item}</option>
                    ))
                  }
                  <option value="Add New">Add New</option>
                </select>
                <label className='material-textfield-label'>
                  City of Origin
                </label>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='table-responsive'>
                <table className='table custom-table'>
                  <tbody>
                  <tr className='cursor-pointer'>
                      <td>
                      Fixed Item(s) 
                        {/* <span className='red-text'>*</span> */}
                      </td>
                      <td>Unit Price</td>
                      <td>Units</td>
                      <td>&nbsp;</td>
                    </tr>
                    {addSelectedFixedItems.map((item, index) => (
                      <tr className='cursor-pointer'>
                        <td className="position-relative">
                        <img
                              onClick={() => {
                                let addselectedLabel=this.state.addselectedLabels
                                addselectedLabel.splice(index,1)
                                this.state.addSelectedFixedItems.splice(index, 1);
                                this.setState({addselectedLabels:addselectedLabel})
                                this.setState({ addSelectedFixedItems, reRender: !this.state.reRender }, () => { 
                                   this.calculateTotalOrderAmount(); });    
                              }}
                              className="delete__icon" src={deleteIconRed} alt=""></img>
                          <div className={`${fixedItemsErrors[index]?.fixedItem !== "" && showErrors ? "mt-custom" :""} form-group material-textfield`}>
                            <select
                            value={this.state.addselectedLabels[index]}
                            className='form-control custom-select material-textfield-input'
                              onChange={(e) => {
                                this.assignSelectedFixedItem(e,index).then(() => { this.calculateTotalOrderAmount()})
                              }}
                              required>
                              <option value={""}>Select</option>
                              {fixedItemsArray &&
                                fixedItemsArray.map((items, inde) => {
                                  return (
                                    <option value={item.label}>{items.label}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                          {showErrors ? (
                            <div className="invalid-feedback d-block">
                              {fixedItemsErrors[index]?.fixedItem}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className=''>
                          <div className={`${fixedItemsErrors[index]?.unitPrice !== "" && showErrors ? "mt-custom" :""} form-group material-textfield unit__price-section`}>
                            <input
                              type='number'
                              min="0"
                              className='form-control material-textfield-input'
                              required
                              value={this.state.addSelectedFixedItems[index].unitPrice > -1 ? this.state.addSelectedFixedItems[index].unitPrice : ''}
                              onChange={(e) => {
                                let addSelectedFixedItems =
                                  this.state.addSelectedFixedItems;
                                  let inputValue = parseFloat(e.target.value);
                                  if (!isNaN(inputValue)) {
                                    inputValue = parseFloat(inputValue.toFixed(2));
                                  }
                                let obj = {}
                                if (addSelectedFixedItems[index]?.dumpRateType === "fixedItem") {
                                  obj = {
                                    dumpRateType: "fixedItem",
                                    fixedItem:
                                      addSelectedFixedItems[index].fixedItem,
                                      unitPrice:
                                      inputValue,
                                        units: addSelectedFixedItems[index].units,
                                  };
                                } else {
                                  obj = {
                                    fixedItem:
                                      addSelectedFixedItems[index].fixedItem,
                                      unitPrice:
                                      inputValue,
                                        units: addSelectedFixedItems[index].units,
                                  };
                                }

                                addSelectedFixedItems[index] = obj;
                                this.setState({
                                  addSelectedFixedItems: addSelectedFixedItems, reRender: !this.state.reRender,
                                }, () => { this.calculateTotalOrderAmount(); });
                                // this.calculateTotalOrderAmount();

                              }}
                            />
                          </div>
                          {showErrors ? (
                            <span className="invalid-feedback d-block">
                              {fixedItemsErrors[index]?.unitPrice}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className={`${fixedItemsErrors[index]?.unit !== "" && showErrors ? "mt-custom" :""} form-group material-textfield unit__price-section`}>
                            <input
                              type='number'
                              min="0"
                              // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              value={
                                this.state.addSelectedFixedItems[index].units >-1 ?  (this.state.addSelectedFixedItems[index].units):""}
                              onChange={(e) => {
                                let addSelectedFixedItems =
                                  this.state.addSelectedFixedItems;
                                  let obj={}
                                  let inputValue = parseFloat(e.target.value);
                                  if (!isNaN(inputValue)) {
                                    inputValue = parseFloat(inputValue.toFixed(2));
                                  }
                                  if(addSelectedFixedItems[index]?.dumpRateType==="fixedItem"){
                                   obj = {
                                    dumpRateType:"fixedItem",
                                      fixedItem:
                                        addSelectedFixedItems[index].fixedItem,
                                      unitPrice:
                                        addSelectedFixedItems[index].unitPrice,
                                      units: parseFloat(inputValue, 10) ,
                                    };
                                  }else{
                                     obj = {
                                      fixedItem:
                                        addSelectedFixedItems[index].fixedItem,
                                      unitPrice:
                                        addSelectedFixedItems[index].unitPrice,
                                      units:parseFloat(inputValue, 10) ,
                                    };
                                  }
                              

                                addSelectedFixedItems[index] = obj;
                                this.setState({
                                  addSelectedFixedItems: addSelectedFixedItems,reRender: !this.state.reRender,
                                },()=>{this.calculateTotalOrderAmount();});
                                // this.calculateTotalOrderAmount();

                              }}
                              className='form-control material-textfield-input'
                              required
                            />
                            {/* <label className="material-textfield-label">
                                      Manifest #
                                    </label> */}
                          </div>
                          {showErrors ? <div className="invalid-feedback d-block">{fixedItemsErrors[index]?.unit}</div>:""}
                        </td>
                        <td className='text-right price__text w-200'>
                          $
                          {(this.state.addSelectedFixedItems.length > 0 && Number(this.state.addSelectedFixedItems[index].unitPrice) * Number(this.state.addSelectedFixedItems[index].units) > 0)
                            ? this.numberWithCommas(Number(
                              this.state.addSelectedFixedItems[index]
                                .unitPrice
                            ) *
                              Number(
                                this.state.addSelectedFixedItems[index].units
                              ))
                            : "-"}
                        </td>
                      </tr>
                    ))}
                    <tr className='border-bottom'>
                      <td
                        className='cursor-pointer underline add__weight-text'
                        colSpan={"4"}>
                        <button
                          onClick={() => {
                            const obj = {
                              fixedItem: "",
                              unitPrice: 0,
                              units: "",
                              clickAfterComplete: this.props.clickedCompleteButton ? true : false,
                            };
                            const items = this.state.addSelectedFixedItems;
                            const labels=this.state.addselectedLabels;
                            labels.push("")
                            items.push(obj);
                            this.setState({addselectedLabels:labels})
                            this.setState({ addSelectedFixedItems: items });
                            this.setState({ reRender: !this.state.reRender });
                            this.props.assignFixedItem(items, this.state.addSelectedChargeByWeight)
                          }}
                          className='cursor-pointer underline add__weight-text TransparentBtn'>
                          {" "}
                          + Add Fixed/Additional Items
                        </button>
                      </td>
                      {/* <td className='text-right'>-</td> */}
                    </tr>

                    <tr className='cursor-pointer'>
                      <td>Charge by Weight</td>
                      <td>Unit Price</td>
                      <td>Tons</td>
                      <td>&nbsp;</td>
                    </tr>
                    {this.state.addSelectedChargeByWeight.length > 0 &&
                      this.state.addSelectedChargeByWeight.map(
                        (item, index) => (
                          <tr className='cursor-pointer border-bottom'>
                            <td className="position-relative">
                              <img
                                onClick={() => {
                                  const temp =
                                    this.state.addSelectedChargeByWeight;
                                    const tempLabels=this.state.addselectedChargeByWeightLabels
                                  temp.splice(index, 1);
                                  tempLabels.splice(index,1)
                                  this.setState({
                                    addSelectedChargeByWeight: temp,addselectedChargeByWeightLabels:tempLabels
                                  });
                                  this.setState({
                                    reRender: !this.state.reRender,
                                  });
                                  this.calculateTotalOrderAmount();
                                }}
                                className="delete__icon"
                                src={deleteIconRed}
                                alt=""
                              />
                              <div className={`${scaleItemsErrors[index]?.fixedItem !== "" && showErrors ? "mt-custom" :""} form-group material-textfield`}>
                                <select
                                value={this.state.addselectedChargeByWeightLabels[index]}
                                className='form-control custom-select material-textfield-input'
                                  onChange={(e) => {
                                    this.assignSelectedChargeByWeight(e,index).then(() => this.calculateTotalOrderAmount())
                                  }}
                                  required>
                                  <>
                                  <option value=''>Select</option>
                                    {this.state.chargeByweightItemsArray
                                      .length > 0 &&
                                      this.state.chargeByweightItemsArray.map(
                                        (item, index) => (
                                          <option value={item.label}>
                                            {item.label}
                                          </option>           
                                        )
                                      )}
                                  </>
                                </select>
                                {/* <label className="material-textfield-label">
                          City of Origin
                          </label> */}
                              </div>
                              {showErrors ?
                               <div className="invalid-feedback d-block">{scaleItemsErrors[index]?.fixedItem}</div>:""}
                            </td>
                            <td className=''>
                            <div className={`${scaleItemsErrors[index]?.unitPrice !== "" && showErrors ? "mt-custom" :""} form-group material-textfield unit__price-section`}>
                            <input
                              type='number'
                              min="0"
                              className='form-control material-textfield-input'
                              required
                              value={this.state.addSelectedChargeByWeight[index].unitPrice > -1 ? this.state.addSelectedChargeByWeight[index].unitPrice : ''}
                              onChange={(e) => {
                                let addSelectedChargeByWeight =
                                  this.state.addSelectedChargeByWeight;
                                  let inputValue = parseFloat(e.target.value);
                                  if (!isNaN(inputValue)) {
                                    inputValue = parseFloat(inputValue.toFixed(2));
                                    }
                                    let obj = {
                                      fixedItem: item.fixedItem,
                                      unitPrice: inputValue,
                                      units: addSelectedChargeByWeight[index].units,
                                    };
                                    addSelectedChargeByWeight[index] = obj;
                                    this.setState({
                                      addSelectedChargeByWeight:
                                        addSelectedChargeByWeight,
                                    });
                                    this.setState({
                                      reRender: !this.state.reRender,
                                    });
                                    this.calculateTotalOrderAmount();
                                  }}
                                />
                                {showErrors ?
                                 <div className="invalid-feedback d-block">{scaleItemsErrors[index]?.unitPrice}</div>:""}
                              </div>
                            </td>
                            <td>
                            <div  className={`${scaleItemsErrors[index]?.unit !== "" && showErrors ? "mt-custom" :""} form-group material-textfield unit__price-section`}>
                                <input
                                  type='number'
                                  maxLength={20}
                                  min={0}
                                  value={
                                    this.state.addSelectedChargeByWeight[index]
                                    .units > -1 ?this.state.addSelectedChargeByWeight[index]
                                    .units :""
                                  }
                                  className='form-control material-textfield-input'
                                  onChange={(e) => {
                                    let addSelectedChargeByWeight =
                                      this.state.addSelectedChargeByWeight;
                                      let inputValue = parseFloat(e.target.value);
                                  if (!isNaN(inputValue)) {
                                    inputValue = parseFloat(inputValue.toFixed(2));
                                  }
                                    let obj = {
                                      fixedItem: item.fixedItem,
                                      unitPrice: item.unitPrice,
                                      units: inputValue,
                                    };
                                    addSelectedChargeByWeight[index] = obj;
                                    this.setState({
                                      addSelectedChargeByWeight:
                                        addSelectedChargeByWeight,
                                    });
                                    this.setState({
                                      reRender: !this.state.reRender,
                                    });
                                    this.calculateTotalOrderAmount();

                                  }}
                                  required
                                />
                              </div>
                              {showErrors ?
                               <div className="invalid-feedback d-block">{scaleItemsErrors[index]?.unit}</div>:""}
                            </td>
                            <td className='text-right price__text w-200'>
                              $
                              {this.state.addSelectedChargeByWeight[index]
                                .units !== undefined && (!isNaN(this.state.addSelectedChargeByWeight[index]
                                  .units)) ? this.numberWithCommas(Number(
                                    this.state.addSelectedChargeByWeight[index]
                                      .units
                                  ) *
                                Number(
                                this.state.addSelectedChargeByWeight[index]
                                .unitPrice
                                )) : 0}
                            </td>
                          </tr>
                        )
                      )}
                    <div className='d-none'>&nbsp;</div>
                    <tr>
                      <td>
                      <div className='cursor-pointer underline add__weight-text'>
                          <button
                            className='cursor-pointer underline add__weight-text TransparentBtn'
                            onClick={() => {
                              let addSelectedChargeByWeight =
                                this.state.addSelectedChargeByWeight;
                                let addselectedChargeByWeightLabels=this.state.addselectedChargeByWeightLabels
                              let obj = {
                                fixedItem: "",
                                unitPrice: 0,
                                units: "",
                                clickAfterComplete: this.props.clickedCompleteButton ? true : false,
                              };
                              addselectedChargeByWeightLabels.push(" ")
                              addSelectedChargeByWeight.push(obj);
                              this.setState({
                                addSelectedChargeByWeight: addSelectedChargeByWeight, addselectedChargeByWeightLabels:addselectedChargeByWeightLabels
                              });
                              this.setState({ reRender: !this.state.reRender });
                              this.props.assignFixedItem(this.state.addSelectedFixedItems, addSelectedChargeByWeight)
                            }}>
                            + Add Weight
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section>
        <div className='row'>
            <div className='col-lg-12'>
              {this.state.isCapOutPrice &&
                <div className='price_par-yard'>
                  This order qualifies for capped pricing. Cap out price is ${this.numberWithCommas(Number(pricingTier.capoutPrice))}
                  (price per yard){" "}
                </div>
              }{this.state.isminimumPrice && <div className='yellow__yard'>
              This order is below the minimum price threshold of ${this.numberWithCommas(Number(pricingTier.minimumPrice))}.The
              total amount has been increased to ${ this.numberWithCommas(Number(pricingTier.minimumPrice))}
            </div>
            }
            </div>
          </div>
        </section>
        <section className='total__order-section'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='totalorder__inner'>
                <div className='totalorder__left-section'>
                  <h2 className='title'>Total Order Amount</h2>
                </div>
                 {this.state.totalOrderAmout >= 0 ? 
                  <div className='totalorder__right-section'>
                    <ul className='list'>
                      {this.state.isCapOutPrice &&
                        <li className='items'>
                          <span className='left-text'>Cap Out Total</span>
                          <span className={this.state.isminimumPrice ? 'amount__text line-through' : "amount__text"}>
                            ${this.numberWithCommas(this.state.totalCapoutPrice)}
                          </span>
                        </li>
                      }
                      { this.props?.isEnvironmentalLoadFee &&
                        <li className='items'>
                          <span className='left-text'>Environmental Load Fee</span>
                          <span className={"amount__text"}>
                            ${this.numberWithCommas(Number(this.state.user?.company?.environmentalLoadFeeAmount))}
                          </span>
                          </li>
                      }
                      { this.props?.isFuelSurchardeFee &&
                        <li className='items'>
                          <span className='left-text'>Fuel Surcharge</span>
                          <span className={"amount__text"}>
                          ${this.numberWithCommas(Number(this.state.fuelSurchargeFeeAmmount))}
                          </span>
                        </li>
                      }
                      { this.props?.isSalesTax && this.state.tax > 0 &&
                        <li className='items'>
                          <span className='left-text'>Sales Tax</span>
                          <span className={"amount__text"}>
                            ${this.numberWithCommas(Number(this.state.tax))}
                          </span>
                        </li>
                      }
                      <li className='items'>
                        <span className='left-text'>Order Amount</span>
                        <span className={this.state.isCapOutPrice ? 'amount__text line-through' : this.state.isminimumPrice ? 'amount__text line-through' : "amount__text"}>
                          {
                            `$` + this.numberWithCommas(this.state.totalOrderAmout)
                          }
                        </span>
                      </li>
                      {this.state.user?.company?.chargeStripeCardFees && this.state.handlingFee > 0 &&
                        <li className='items'>
                          <span className='left-text'>Handling Fee</span>
                          <span className={"amount__text"}>
                            ${this.numberWithCommas(Number(this.state.handlingFee))}
                          </span>
                        </li>
                      }
                      <li className="items">
                        <span className="left-text">Amount Due </span>
                        <span className="amount__text">
                          ${this.numberWithCommas(this.state.totalAmountDue)} {this.state.isActualRoundOffAmount &&  <small><pre class="m-0">(Rounded)</pre></small> }
                        </span>
                      </li>
                    </ul>
                  </div>
                   : "-"} 
              </div>
            </div>
          </div>
        </section>
        { this.state.isCityOfOriginModalOpen &&
          <CityOfOrigin
            companyId={this.state.user?.companyId}
            cities={this.state.cityOfOrigin}
            isOpen={this.state.isCityOfOriginModalOpen}
            updateCompany ={this.props.updateCompany}
            assignCityOfOrigin={(city) => this.props?.cityOfOrigin(city)}
            closeModal={(cityOfOriginSelected) => {
              this.setState({ isCityOfOriginModalOpen: !this.state.isCityOfOriginModalOpen})
            }}
            {...this.props}
          />
        }
      </div>
    );
  }
}
