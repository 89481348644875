import { connect } from 'react-redux'
import { getMe ,updateUserToken,fetchMe} from '../../store/user/duck'
import { fetchCustomers, updateCustomer } from "../../store/customer/duck";
import { fetchCustomer, updateDriver, updateTruck, deletePaymentMethod,fetchProjects, createProject,updateProject,updateTruckWithIndex,fetchLogs, fetchDriversByCustomerId, fetchTrucksByCustomerId, fetchCustomerPricing, fetchCustomerTrucks, fetchCustomerPastWeignIns } from '../../store/customer/duck'
import { getPayabliCustomerData } from '../../store/paybli_integrations/duck';
import CustomersComponent from './component'
import {getInvoiceDeliveryByIdData} from "../../store/invoiceDelivery/duck"
import {getRecivingPaymentInvoices,getInvoiceDetalByInvoiceId,updateInvoicDetail,getCustomercreditsForCustomers,getReceivePaymentForCustomers, syncPostedOrder,customerInvoicesByCustomerId, getQbDetails,getReceivePaymentForPdf, sendInvoiceEmail,batchPrintInvoice, printPaymentReciept , customerOpenBalanceInvoicesByCustomerId,getCompanyById,generateStatementPdf, statementEmail, sendInvoiceEmail2} from '../../store/Billing/duck'
import { getOrderInvoicePdf,fetchProjectOrderList, fetchOrder } from "../../store/order/duck";
import { fetchContainers } from '../../store/container/duck';
import { fetchSetting} from '../../store/company/duck';

const CustomersContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getQbDetails,
    syncPostedOrder,
    getMe,
    fetchMe,
    fetchCustomers,
    fetchCustomer,
    updateUserToken,
    updateCustomer,
    updateDriver,
    getCompanyById,
    generateStatementPdf,
    statementEmail,
    updateTruck,
    deletePaymentMethod,
    getPayabliCustomerData,
    getInvoiceDeliveryByIdData,
    getRecivingPaymentInvoices,
    getInvoiceDetalByInvoiceId,
    sendInvoiceEmail2,
    updateInvoicDetail,
    getCustomercreditsForCustomers,
    getReceivePaymentForCustomers,
    customerInvoicesByCustomerId,
    customerOpenBalanceInvoicesByCustomerId,
    getOrderInvoicePdf,
    getReceivePaymentForPdf,
    fetchContainers,
    fetchProjects,
    createProject,
    updateProject,
    fetchProjectOrderList,
    sendInvoiceEmail,
    fetchOrder,
    fetchLogs,
    fetchSetting,
    updateTruckWithIndex,
    batchPrintInvoice,
    printPaymentReciept,
    fetchDriversByCustomerId,
    fetchTrucksByCustomerId,
    fetchCustomerPricing,
    fetchCustomerTrucks,
    fetchCustomerPastWeignIns
  }
)(CustomersComponent);

export default CustomersContainer
